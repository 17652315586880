import Vue from 'vue'
import Vuex from 'vuex'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import moment from 'moment'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';
import i18n from './i18n'

const TitleSuffix = process.env.VUE_APP_TITLE_SUFFIX;
const API_URL = process.env.VUE_APP_API_BASE_URL;
const COMPANY = process.env.VUE_APP_COMPANY_ID;

Vue.component('v-select', vSelect)
Vue.prototype.moment = moment
Vue.prototype.$store = store
Vue.use(VueMeta).use(Vuex).use(VueFileAgent);




Vue.config.productionTip = false

Vue.filter('date', function (value) {
  let DATE_FORMAT = i18n.t('DATE_FORMAT')
  return moment(value).format(DATE_FORMAT);
});

Vue.filter('text', function (value) {
  if ( value ) {
    return value
  } else {
    return i18n.t('NOT_DISCLOSED')
  }
});

Vue.filter('urlEnc', function (value) {
  encodeURI(value);
});

Vue.filter('EUR', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.mixin({
  methods:{
    RH_USER(){
      return store.getters['getUserName']
    },
    RH_USER_PROFILE(){
      return store.getters['getUserRole']
    },
    RH_USER_PROFILE_IMG(){
      return (API_URL + 'Image/user?intern=' + COMPANY + '&partner=' + store.getters['getUserPartner'] + '&user=' + store.getters['getUserName']+ '&username=' + store.getters['getUserName'])
    },
    RH_API_URL(){
      return process.env.VUE_APP_API_BASE_URL;
    },
    RH_API_KEY(){
      return store.getters['getToken']
    },
    RH_INTERN_ID(){
      return store.getters['getUserIntern']
    },
    RH_PARTNER_ID(){
      return store.getters['getUserPartner']
    },
    RH_LOCALE(){
      return store.getters['getCurrentLocale']
    },
    displayName(){
      let displayName = this.$store.getters['getUserFirstName'] + " " +  this.$store.getters['getUserLastName']
      return displayName
    },
    ResellerNation(){
      return store.getters['getResellerNation']
    }, 
    ResellerCity(){
      return store.getters['getResellerCity']
    }, 
    }
})

new Vue({
  router,
  store,
  axios,
  i18n,
  render: h => h(App),
  metaInfo: () => ({
    title: 'Reseller Hub',
    titleTemplate: '%s ' + TitleSuffix,
    htmlAttrs: {
      lang: 'it',
      amp: undefined
    },
    meta: [
      { name: 'description', content: 'Hello', vmid: 'test' }
    ]
})

}).$mount('#app')