<template>
<header>
    <div class="Container" v-bind:class="{ usermenu:showUserMenu }">
        <div class="Box Flexbox">
            <div class="Logo">
                <h1>
                    <a href="/">
                    <span>Nubess Reseller Hub</span><img src="/assets/images/Logo.png" srcset="/assets/images/LogoRetina.png 2x" alt="Nubess Reseller Hub" /></a></h1></div>
            <div class="MainMenuBox">
                <button class="NavOpenButton"><img src="/assets/images/NavOpenIcon.png" srcset="/assets/images/NavOpenIconRetina.png 2x" alt="Menu" /></button>
                <nav class="MainMenu">
                    <div class="VisibleHiddenPanel"></div>
                    <div class="Box MenuItemBox">
                        <button class="NavCloseButton"><img src="/assets/images/NavCloseWhiteIcon.png" srcset="/assets/images/NavCloseWhiteIconRetina.png 2x" alt="Menu" /></button>
                        <ul class="PrimaryNav">
                            <li><router-link to="/" active-class="SelectedItem" >{{$t('MENU.HOME')}}</router-link></li>
                            <li><router-link to="/accounts" active-class="SelectedItem">{{$t('MENU.ACCOUNTS')}}</router-link></li>
                            <li><router-link to="/organization" active-class="SelectedItem">{{$t('MENU.ORGANIZATION')}}</router-link></li>
                            <li><router-link to="/assistance" active-class="SelectedItem">{{$t('MENU.ASSISTANCE')}}</router-link></li>
                            <li><router-link to="/academy" active-class="SelectedItem">{{$t('MENU.ACADEMY')}}</router-link></li>
                            <li><router-link to="/marketing" active-class="SelectedItem">{{$t('MENU.MARKETING')}}</router-link></li>
                            <li><router-link to="/bulletin" active-class="SelectedItem">{{$t('MENU.BULLETIN')}}</router-link></li>
                        </ul>
                    </div>
                </nav>
            </div>
            <div class="ActionBox">
                <div class="TopLink">
                    <button class="UserMenuOpenButton" @click="UserMenu()"><span>{{ displayName () }}</span>
                    <img ref="image" :src="RH_USER_PROFILE_IMG()"/>
                    </button>
                    <nav class="UserMenu">
                        <div class="UserData">
                    <img ref="image" :src="RH_USER_PROFILE_IMG()"/>
                            <div class="UserDataInfo">
                                <div class="UserDataName">{{ displayName () }}</div>
                                <div class="UserDataRole">{{RH_USER_PROFILE()}}</div>
                            </div>
                        </div>
                        <div class="LanguageSettings">
                            <div class="LanguageOption">
                                <ul>
                                    <li v-bind:class="[currentLang === 'it' ? 'SelectedItem' : '']"><a @click="SetLocale('it')" href="#"><img src="/assets/images/FlagIT.png" srcset="" alt=""/>Italiano (Italia)</a></li>
                                    <li v-bind:class="[currentLang === 'en' ? 'SelectedItem' : '']"><a @click="SetLocale('en')" href="#"><img src="/assets/images/FlagEN.png" srcset="" alt=""/>English (United Kingdom)</a></li>
                                    <!-- <li><a href="#"><img src="/assets/images/FlagGE.png" srcset="" alt=""/>Deutsch (Deutschland)</a></li> -->
                                    <!-- <li><a href="#"><img src="/assets/images/FlagFR.png" srcset="" alt=""/>Français (France)</a></li> -->
                                </ul>
                            </div>
                        </div>
                        <ul class="UserLinkBox">
                            <!-- <li><a href="/userprofile.php"><img src="/assets/images/UserWhiteIcon.png" srcset="/assets/images/UserWhiteIconRetina.png 2x" alt=""/><span>User Profile</span></a></li> -->
                            <!-- <li><a href="/companyprofile.php"><img src="/assets/images/CompanyWhiteIcon.png" srcset="/assets/images/CompanyWhiteIconRetina.png 2x" alt=""/><span>Company Profile</span></a></li> -->
                            <!-- <li><a href="#"><img src="/assets/images/PasswordWhiteIcon.png" srcset="/assets/images/PasswordWhiteIconRetina.png 2x" alt=""/><span>Password</span></a></li> -->
                            <li>
                                <a @click="logMeOut()" href="#">
                                    <img src="/assets/images/LogoutWhiteIcon.png" srcset="/assets/images/LogoutWhiteIconRetina.png 2x" alt=""/>
                                    <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</header>
</template>

<script>
export default {
   data() {
        return {
            ProfilePicture: null,
            showUserMenu: false,
            currentLang: this.$store.getters['getCurrentLocale'],
            currentUser: this.$store.getters['getUserName'],
        }
   }, 
  name: 'Header',
    computed:{
      UserIsLogged() {
        return this.$store.getters['getUserStatus']
      },
    },
    methods:{
    UserMenu() {
        if (this.showUserMenu) {
            this.showUserMenu = false
            } else {
            this.showUserMenu = true
            }
        },
    logMeOut() {
            this.$store.dispatch('LogOut')
        },
    SetLocale(LANG){
            this.$store.dispatch('SetLocale', LANG)
            this.$i18n.locale = LANG
            location.reload();
        },
    }
}
</script>
<style>
.LanguageOption ul li.SelectedItem a{
    font-weight: bold;
    color: #fff!important;
}
</style>