<template>
  <div class="reseller-hub">
    <Loader v-if="AppIsLoading"/>
    <Header v-if="UserIsLogged"/>
      <router-view/>
    <Footer v-if="UserIsLogged"/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'

export default {
    components: { 
      Header,
      Footer,
      Loader,
    },
    computed:{
      AppIsLoading() {
        return this.$store.getters['getLoadingStatus']
      },
      UserIsLogged() {
        return this.$store.getters['getUserStatus']
      }
    },
    methods: {
    },
  }
</script>
