<template>
<footer>
    <a class="TopSwitch" href="#PageTop"><img src="/assets/images/TopSwitchIcon.png" srcset="/assets/images/TopSwitchIconRetina.png 2x" alt="Top of the page" /></a>
    <div class="Container">
        <div class="Box">
            <div class="FooterLogo"><a href="/"><img src="/assets/images/FooterLogo.png" srcset="/assets/images/FooterLogoRetina.png 2x" alt="Nubess Reseller Hub" /></a></div>
            <h2><span>Nubess Reseller Hub</span> è un prodotto Nubess srl</h2>
            <div class="FooterCopy">© Copyright 2021/22</div>
        </div>
    </div>
</footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>