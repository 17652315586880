import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
import router from '@/router'


let API_URL = process.env.VUE_APP_API_BASE_URL;
let API_BASE_AUTH = process.env.VUE_APP_API_BASE_AUTH;
let COMPANY = process.env.VUE_APP_COMPANY_ID;

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn : false,
    Token : null,
    AppIsLoading : false,
    User : [],
    PATHAPI : process.env.VUE_APP_API_BASE_URL,
    CurrentLocale : 'it'
  },
  mutations: {
    AuthorizationRequest(state) {
      state.loggedIn = false
      state.Token = null
      state.AppIsLoading = true
    },
    AuthorizationGranted(state, token) {
      state.loggedIn = true
      state.Token = token
      state.AppIsLoading = false
    },
    AuthorizationError(state) {
      state.loggedIn = false
      state.Token = null
      state.AppIsLoading = false
    },
    LogOut(state) {
      state.loggedIn = false
      state.Token = null
      state.AppIsLoading = false
      state.User = []

    },
    SetUser (state, user){
      state.User = user
    },
    SetUserProfilePicture (state, PicURL){
      state.getProfilePictureUrl = PicURL
    },
    SetLocale (state, locale){
      state.CurrentLocale = locale
    },
    loadingData(state) {
      state.AppIsLoading = true 
    },
    dataLoaded(state) {
      state.AppIsLoading = false 
    }
  },

  actions: {
    async loginAction({ commit }, auth) {
      let b64 = Buffer.from(JSON.stringify({
        username: auth.user,
        password: auth.pass,
        idCompany: COMPANY,
        idPortale: 'RESELLER'
      })).toString("base64")
      
      commit('AuthorizationRequest')

        axios({
          url: API_URL + 'account/login',
          data: b64,
          method: 'POST',
          headers: {
            'Authorization': API_BASE_AUTH,
            'content-type': 'application/json'
          }
        })
        .then(resp => {
          let StatusCode = parseInt(resp.data.StatusCode)
          if (StatusCode >= 200 && StatusCode <= 299) {
            if (resp.data.Data.token) {
              let AuthToken = resp.data.Data.token;
              commit('AuthorizationGranted', AuthToken)
              this.dispatch('getProfile')
              this.dispatch('getProfilePicture')
              router.push('/')
            }
          }
        })
        
    },

    async getProfile({ commit }) {
        axios({
            url: API_URL + 'profile?intern=' + COMPANY,
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + this.state.Token,
              'content-type': 'application/json'
            }
          })
          .then(resp => {
            let StatusCode = parseInt(resp.data.StatusCode)
            if (StatusCode >= 200 && StatusCode <= 299) {
              commit('SetUser', resp.data.Data)
            }
          })
          .catch(err => {
            console.log(err)
          })

    },

    async getProfilePicture({ commit }) {
      axios({
        url: API_URL + 'Image/user?intern=' + this.getters['getUserPartner'] + '&partner=' + this.getters['getUserPartner'] + '&user=' + this.state.User.user + '&username=' + this.state.User.user,
        method: 'GET',
        responseType: 'Blob',
        headers: {
          'Authorization': 'Bearer ' + this.state.Token,
        }
      })
      .then(resp => {
          commit('SetUserProfilePicture', URL.createObjectURL (resp))
      })
      .catch(err => {
        console.log(err)
      })

  },
  async SetLocale({commit}, LOCALE) {
      commit('SetLocale', LOCALE)
    },
  LogOut({commit}) {
      commit('LogOut')
      router.push('/login')
    }
  },
  getters: {
    getLoadingStatus (state) {
      return state.AppIsLoading
    },
    getUserStatus (state) {
      return state.loggedIn
    },
    getApiPath (state) {
      return state.PATHAPI
    },
    getToken (state) {
      return state.Token
    },
    getUserName (state) {
      return state.User.User
    },
    getUserIntern (state) {
      return state.User.Intern
    },
    getUserPartner (state) {
      return state.User.Partner
    },
    getUserFirstName (state) {
      return state.User.Nome
    },
    getUserLastName (state) {
      return  state.User.Cognome
    },
    getUserRole (state) {
      return state.User.Profile
    },   
    getCurrentLocale (state) {
      return  state.CurrentLocale
    },
    getProducts (state) {
      return state.User.Prodotti
    },
    getResellerNation (state) {
      return state.User.NazionePartner
    },
    getResellerCity (state) {
      return state.User.CittaPartner
    },
  },
  plugins: [createPersistedState()],
})